<template>
  <section class="pd-20">
    <aPageHeader class="pd-0 mb-0">
      <template #title>
        <h1>
          <img
            src="@/assets/images/dashboard/credit-letter/list-ico.png"
            alt="img"
            width="30"
          />
          Campanhas
        </h1>
      </template>
      <template #extra>
        <downloadExcel
          :header="excelFile.header"
          :name="excelFile.fileName"
          :data="opportunity.list"
          :fields="excelFile.collumns"
          :footer="excelFile.footer"
        >
          <img
            class="c-pointer mt-5 mr-5"
            src="@/assets/images/dashboard/excel.png"
          />
        </downloadExcel>
      </template>
    </aPageHeader>

    <CampaignsFilterCollapse
      :optFilters="opportunity.filters"
      @onClickEmitGetOpportunities="onClickEmitGetOpportunities"
    />

    <a-table
      class="travel-table"
      :columns="optTableColumns"
      :data-source="opportunity.list"
      :loading="opportunity.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="opportunityTableChange"
    >
      <div slot="created" slot-scope="record">
        {{ record.created | formatMultiDateTime }}
      </div>

      <div slot="customer_type" slot-scope="record">
        {{ record.customer_type }}
      </div>

      <div slot="customer" slot-scope="record">
        <div v-if="record.customer">
          <div
            v-if="record.customer.person_type == 'Pessoa Física'"
            class="ml-5 dotted-phrase upper"
          >
            {{ record.customer.id }} - {{ record.customer.first_name }}
            {{ record.customer.last_name }}
          </div>
          <div
            v-if="record.customer.person_type == 'Pessoa Jurídica'"
            class="ml-5 dotted-phrase upper"
          >
            {{ record.customer.id }} - {{ record.customer.trading_name }}
          </div>
        </div>
        <div v-else>{{ record.customer_name }}</div>
      </div>

      <div slot="origin" slot-scope="record">
        {{ record.origin }}
      </div>

      <div slot="product_type" slot-scope="record">
        <a-tag
          v-for="(product, index) in theProducts(record.meta)"
          :key="index"
          :color="product.color"
        >
          <div v-html="product.name" />
        </a-tag>
      </div>

      <div slot="name" slot-scope="text">{{ text }}</div>

      <div slot="contract_sale" slot-scope="record">
        {{ record.contract != 0 && record.sale != 0 ? "Sim" : "Não" }}
      </div>

      <div slot="utm_source" slot-scope="record">
        {{ record.meta.utm_source }}
      </div>

      <div slot="utm_medium" slot-scope="record">
        {{ record.meta.utm_medium }}
      </div>

      <div slot="utm_campaign" slot-scope="record">
        {{ record.meta.utm_campaign }}
      </div>

      <div slot="value" slot-scope="record">
        {{ record.value | formatPricePtBr }}
      </div>

      <div class="travel-actions" slot="action" slot-scope="record">
        <a-tooltip placement="top" title="Editar">
          <a
            class="edit"
            @click="$router.push(`/opportunity/edit/${record.id}`)"
          >
            <a-icon type="edit-svg" />
          </a>
        </a-tooltip>
      </div>
    </a-table>

    <div class="a-center mb-60">
      <a-pagination
        show-size-changer
        :default-current="opportunity.pagination.page"
        :page-size.sync="opportunity.pagination.perPage"
        :total="opportunity.pagination.total"
        @change="changeOpportunityPage"
        @showSizeChange="changeOpportunityPageSize"
      >
      </a-pagination>
    </div>
  </section>
</template>

<script>
import { format } from "date-fns";
import formatThings from "@/mixins/general/formatThings";
import opportunityMixins from "@/components/pipeline/mixins/opportunityMixins";
import CampaignsFilterCollapse from "@/components/marketing/collapses/CampaignsFilterCollapse.vue";

export default {
  name: "Campaigns",
  components: { CampaignsFilterCollapse },
  mixins: [opportunityMixins, formatThings],
  data() {
    return {
      optTableColumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 65,
          sorter: true,
        },
        {
          title: "Data",
          scopedSlots: { customRender: "created" },
          key: "created",
          sorter: true,
          width: 140,
        },
        {
          title: "Tipo de cliente",
          scopedSlots: { customRender: "customer_type" },
          width: 130,
          key: "customer_type",
          sorter: true,
        },
        {
          title: "Cliente",
          width: 250,
          scopedSlots: { customRender: "customer" },
          key: "customer_name",
        },
        {
          title: "Origem",
          scopedSlots: { customRender: "origin" },
          key: "origin",
          sorter: true,
          width: 190,
        },
        {
          title: "Tipo de Produto",
          scopedSlots: { customRender: "product_type" },
          key: "product_type",
          sorter: true,
          width: 150,
        },

        {
          title: "Produto",
          width: 250,
          dataIndex: "title",
          key: "title",
          sorter: true,
        },
        {
          title: "Contrato/venda",
          scopedSlots: { customRender: "contract_sale" },
          key: "contract_sale",
          width: 140,
        },

        {
          title: "UTM Source",
          scopedSlots: { customRender: "utm_source" },
          key: "utm_source",
          width: 120,
        },

        {
          title: "UTM Medium",
          scopedSlots: { customRender: "utm_medium" },
          key: "utm_medium",
          width: 150,
        },

        {
          title: "UTM Campaign",
          scopedSlots: { customRender: "utm_campaign" },
          key: "utm_campaign",
          width: 200,
        },
        {
          title: "Valor",
          width: 150,
          key: "value",
          scopedSlots: { customRender: "value" },
          sorter: true,
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 60,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      excelFile: {
        header: "Campanhas",
        fileName: "Campanhas.xls",
        collumns: {
          ID: "id",
          Data: {
            field: "created",
            callback: (created) => {
              return `${this.formatDateTime(created)}`;
            },
          },
          "Tipo de cliente": "customer_type",
          Cliente: {
            field: "customer",
            callback: ({
              first_name,
              last_name,
              person_type,
              trading_name,
            }) => {
              return person_type === "Pessoa Física"
                ? `${first_name} ${last_name}`
                : `${trading_name}`;
            },
          },
          Origem: "origin",
          "Tipo de Produto": {
            field: "meta",
            callback: (meta) => {
              return `${this.theProductsTxt(meta)}`;
            },
          },
          Produto: "title",
          "UTM Source": {
            field: "meta",
            callback: ({ utm_source }) => {
              return `${utm_source || ""}`;
            },
          },
          "UTM Medium": {
            field: "meta",
            callback: ({ utm_medium }) => {
              return `${utm_medium || ""} `;
            },
          },
          "UTM Campaign": {
            field: "meta",
            callback: ({ utm_campaign }) => {
              return `${utm_campaign || ""}`;
            },
          },
          Valor: {
            field: "value",
            callback: (value) => {
              return `${this.formatPrice(value)}`;
            },
          },
        },
        data: [],
        footer: [],
      },
    };
  },
  mounted() {
    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth(),
      firstDay = new Date(y, m, 1),
      lastDay = new Date(y, m + 1, 0);

    firstDay = format(firstDay, "yyyy-MM-dd");
    lastDay = format(lastDay, "yyyy-MM-dd");

    this.opportunity.filters.period.selected = [`${firstDay}`, `${lastDay}`];
    this.opportunity.pagination.perPage = 20;
    this.getOpportunities();
  },
  methods: {
    onClickEmitGetOpportunities(filters) {
      this.opportunity.filters = filters;
      this.getOpportunities();
    },
    theProductsTxt(meta) {
      let contractedServices = [],
        hotel = [],
        flight = [],
        service = [];

      Object.keys(meta).forEach((key) => {
        if (key.includes("hotel")) hotel.push(key);
        if (key.includes("flight")) flight.push(key);
        if (key.includes("service")) service.push(key);
      });

      if (service.length > 0) contractedServices += "Serviço, ";
      if (flight.length > 0) contractedServices += "Aéreo, ";
      if (hotel.length > 0) contractedServices += "Hotel, ";

      return contractedServices.slice(0, -2);
    },
    theProducts(meta) {
      let contractedServices = [],
        hotel = [],
        flight = [],
        service = [];

      Object.keys(meta).forEach((key) => {
        if (key.includes("hotel")) hotel.push(key);
        if (key.includes("flight")) flight.push(key);
        if (key.includes("service")) service.push(key);
      });

      if (service.length > 0)
        contractedServices.push({
          name: "Serviço",
          color: "orange",
        });
      if (flight.length > 0)
        contractedServices.push({
          name: "Aéreo",
          color: "blue",
        });
      if (hotel.length > 0)
        contractedServices.push({
          name: "Hotel",
          color: "green",
        });

      return contractedServices;
    },
  },
};
</script>
